import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Viewer from 'v-viewer/src/component.vue'
import map from 'lodash/map'
import sum from 'lodash/sum'
import uniq from 'lodash/uniq'
import sumBy from 'lodash/sumBy'
import filter from 'lodash/filter'
import snakeCase from 'lodash/snakeCase'
import lowerCase from 'lodash/lowerCase'
import find from 'lodash/find'
import size from 'lodash/size'
import round from 'lodash/round'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import remove from 'lodash/remove'
import includes from 'lodash/includes'
import Dropzone from 'vue2-dropzone'

// Base Page
import BasePage from '@/pages/Base'

// Services
import OrdersService from '@/services/Orders'
import PackageService from '@/services/Package'
import StatusService from '@/services/Status'
import { type } from 'os'
import uniqBy from 'lodash/uniqBy'

// Configs
import config from '@/configs'

const storage = window.localStorage
const baseUrl = config.api.baseURL

@Component({ components: { Viewer, Dropzone } })
export default class PackageDetails extends BasePage {
  constructor() {
    super()
  }

	packagingModal: any = {
	  open: false
	}

	packagingModalEdit: any = {
	  open: false
	}

	packingDeleteModal: any = {
	  open: false
	}

	trackingStatusDeleteModal: any = {
	  open: false
	}

	trackingStatusAddModal: any = {
	  open: false
	}

	idDelete: any = null
	idDeleteTrackingStatus: any = null
	idUpdate: any = null
	type: any = null

	//Product Items
	products: any = []
	unitItems: any = [{ text: 'Pcs', value: 'pcs' }, { text: 'Sheets', value: 'sheets' }, { text: 'Boxes', value: 'boxes' }]
	// packageItems: any = [{ text: 'Pack', value: 'pack' }, { text: 'Sack', value: 'sack' }, { text: 'Box', value: 'box' }, { text: 'Carton', value: 'carton' }, { text: 'Container', value: 'container' }]
	packageItems: object[] = [
	  {
	    text: 'CRT',
		  	value: 'crt'
	  },
	  {
		  	text: 'LSN',
		  	value: 'lsn'
	  },
	  {
	    text: 'BOX',
	    value: 'box'
	  },
	  {
	    text: 'PAK',
	    value: 'pak'
	  },
	  {
	    text: 'SET',
	    value: 'set'
	  },
	  {
	    text: 'GRS',
	    value: 'grs'
	  }
	]
	packageMultipleItems: any = [{ text: '1 Pack', value: 'pack' }, { text: '1 Sack', value: 'sack' }, { text: '1 Box', value: 'box' }, { text: '1 Carton', value: 'carton' }, { text: '1 Container', value: 'container' }]
	dimensionItems: any = [{ text: 'Meters', value: 'meters' }, { text: 'Inchs', value: 'inchs' }, { text: 'Centimeters', value: 'cms' }]
	orderItems: any = [{
	  id: '',
	  productQuantity: '',
	  productUnit: '',
	  products: null,
	  placeholder: ''
	},{
	  id: '',
	  productQuantity: '',
	  productUnit: '',
	  products: null
	}]
	packagingData: any = {
	  orderItems: null,
	  marks: '',
	  packageType: '',
	  nettWeight: '',
	  grossWeight: '',
	  length: '',
	  width: '',
	  height: '',
	  dimensionUnit: '',
	  packageQuantity: 1
	}
	packagingDataSingle: any = {
	  id: '',
	  productQuantity: '',
	  productUnit: '',
	  marks: '',
	  packageType: '',
	  nettWeight: '',
	  grossWeight: '',
	  length: '',
	  width: '',
	  height: '',
	  dimensionUnit: '',
	  packageQuantity: '',
	  placeholder: '',
	  qtyModuloTemp: '',
	  qtyPackageBundleDefault: ''
	}
	quantityValue: number = 0	
	firstGet: boolean = false
	
	tab: any = null
	dataPackage: any = {
	  name: '',
	  tracking_code: '',
	  courier_name: '',
	  last_mile_courier: '',
	  tracking_code_courier: '',
	  custom_tracking: false,
	  packaging: null
	}

	tablePackageItem : object[] = [
	  {
	    text: 'Actions',
	    align: 'center',
	    sortable: false,
	    value: 'images',
	    class: 'action-table-width'
	  },
	  {
	    text: 'Images',
	    align: 'center',
	    sortable: false,
	    value: 'images',
	    class: 'action-table-width'
	  },
	  {
	    text: 'Package Marks No.',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  },
	  {
	    text: 'Order Item Name',
	    align: 'center',
	    sortable: false,
	    width: '30%'
	  },
	  {
	    text: 'Product Quantity / Package',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  },
	  {
	    text: 'Package Quantity',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  },
	  {
	    text: 'Total Product Quantity',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  },
	  {
	    text: 'N.W.(KGS)',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  },
	  {
	    text: 'G.W.(KGS)',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  },
	  {
	    text: 'Dimension (W x H x L)',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  },
	  {
	    text: 'CBM',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  }
	]

	tableTrackingItem : object[] = [
	  {
	    text: 'Actions',
	    align: 'left',
	    sortable: false,
	    value: 'images',
	    class: 'action-table-width'
	  },
	  {
	    text: 'Date',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  },
	  {
	    text: 'Tracking Status',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  },
	  {
	    text: 'Comment',
	    align: 'center',
	    sortable: false,
	    class: 'action-table-width'
	  }
	]

	tableItemsPackageItem: object[] = []
	tableLoading: boolean = false
	dataDetails: any = []
	availableItem: any = []
	tableItemsTrackingItem: object[] = []
	orderId: number = 0	
	orderCode: string = ''	
	moduloProductQuantity: number = 0	
	defaultProductQuantity: number = 0	
	totalNettWeight: number = 0	
	totalWeight: number = 0	
	totalCBM: number = 0	
	alreadyPacked: number = 0	
	isEditTracking: boolean = false

	addTrackingStatus: any = {
	  trackingStatusItems: [],
	  valueStatus: null,
	  newTrackingStatus: null,
	  status_id: null,
	  date : null,
	  comment: null,
	  trackingStatusDate: {
	    open: false,
	    date: null,
	    dateFormatted: null,
	    tempDate: null
	  },
	  trackingStatusTime: {
	    open: false,
	    time: null,
	    // timeFormatted: null,
	    tempTime: null
	  }
	}
	selectedEditId: string | number = null
	showModalAddImage: boolean = false
	updateImageId: string = ''
	deletedImages: any = []
	token: string = storage.getItem('access_token')
	options: any = {
	  url: 'https://httpbin.org/post', // dummy post data. this will change later after adding an image
	  headers: {
		  Authorization: 'Bearer ' + this.token,
		  Identifier: 'manage'
	  },
	  addRemoveLinks: true,
	  dictDefaultMessage: 'Click Here or Drop Image Here To Upload',
	  autoProcessQueue: false,
	  acceptedFileTypes: 'image/*',
	  maxNumberOfFiles: 10,
	  maxFileSizeInMB: 2,
	  duplicateCheck: true,
	  thumbnailHeight: 250,
	  thumbnailWidth: 250,
	  paramName: 'images',
	  parallelUploads: 10,
	  uploadMultiple: true
	}
	dataImage: any = []
	dataViewer: any = []

	imageOptions: object = {}

	// quantityProduct: number = 0
	dataSelectedOrderItem: any = []
	isBundle: boolean = false

	// @Watch("packagingDataSingle.productQuantity", { deep: true })
	// onChanged() {
	// 	console.log('Watch Kerender productQuantity?', this.moduloProductQuantity)
	// 	this.getModuloQuantity()
	// }

	// @Watch("packagingDataSingle.packageQuantity", { deep: true })
	// onChangeded() {
	// 	console.log('Watch Kerender packageQuantity?', this.moduloProductQuantity)
	// 	this.getModuloQuantity()
	// }

	getModuloQuantity(){
	  this.firstGet = false
	  // console.log('Watch Kerender?', this.moduloProductQuantity)
	  // console.log('this.packagingDataSingle.id', this.packagingDataSingle.id)
	  this.moduloProductQuantity = this.defaultProductQuantity
	  if(this.packagingDataSingle.productQuantity !== '' && this.packagingDataSingle.packageQuantity !== ''){
	    if(this.packagingDataSingle.productQuantity == 0 || this.packagingDataSingle.packageQuantity == 0){
	      this.moduloProductQuantity = this.defaultProductQuantity
	    }else{
	      this.moduloProductQuantity = this.moduloProductQuantity - (this.packagingDataSingle.productQuantity * this.packagingDataSingle.packageQuantity)
	    }
	  }else{
	    this.moduloProductQuantity = this.defaultProductQuantity
	  }

	  if(this.packagingDataSingle.id == ''){
	    this.moduloProductQuantity = 0
	  }
	  // console.log('Modulo Bawah', this.moduloProductQuantity)
	}

	mounted(){
	  this.getTrackingStatusList()
	  this.getTrackingStatus()
	  this.getPackageListDetails()
	}

	getOrderItem(packingId){
	  try {
	    const opts: any = {
	      params: {
	        include: 'items,images'
	      }
	    }

	    const response = PackageService.getOnePacking(
	      packingId,
	      opts
	    )

	    return response
	  } catch (error) {
	    this.catchHandler(error)
	  }
	}

	async getOrderDetails(orderId, availableOrderItem) {
	  try {
	    const opts: any = {
	      params: {
	        include: 'items,invoices,platforms,parent,child,packaging'
	      }
	    }
	    this.tableLoading = true

	    const response = await OrdersService.getOneOrder(
	      orderId,
	      opts
	    )

	    // console.log('response', response.data.attributes.order_code)
	    this.orderCode = response.data.attributes.order_code

	    // console.log('this.availableOrderItem', availableOrderItem)

	    // // console.log('response.included.packaging', response.included.packaging)
	    // await forEach(response.included.packaging, (packing: any) => {
	    // 	availableOrderItem = []
	    // 	const optsPacking: any = {
	    // 		params: {
	    // 			include: "items"
	    // 		}
	    // 	}

	    // 	const responsePacking = PackageService.getOnePacking(
	    // 		packing.attributes.id,
	    // 		optsPacking
	    // 	)
	
	    // 	// console.log('responsePacking', responsePacking)
	    // 	responsePacking.then(function(result) {
	    // 		if(result.data.relationships.items.length !== 0){
	    // 			forEach(result.data.relationships.items, (item: any) => {
	    // 				availableOrderItem.push({
	    // 					id: Number(item.id),
	    // 					qty: result.included.items[Number(item.id)].attributes.pivot.qty*result.data.attributes.package_qty
	    // 				})
	    // 			})
	    // 		}
	    // 	})
	    // })

	    this.availableItem = []
	    const idUniq = uniq(map(availableOrderItem, 'id'))
	    var temp
	    forEach(idUniq, (iU: any) => {
	      temp = 0
	      forEach(availableOrderItem, (aOI: any) => {
	        if(iU == aOI.id){
	          temp += aOI.qty
	        }
	      })
	      this.availableItem.push({
	        id: iU,
	        qty: temp
	      })
	    })

	    this.products = []
	    this.alreadyPacked = 0
	    this.dataDetails = response.data.attributes.details
	    // console.log('this.dataDetails', this.dataDetails)
	    // console.log('this.availableItem', this.availableItem)
	    forEach(this.dataDetails, (dataItem: any) => {
	      const data = dataItem.attributes
	      if(!isEmpty(data.variants)){
	        let text = data.product_name
	        forEach(data.variants, (variantDetail: any) => {
	          if(variantDetail.option != text) {
	            text = text + ' - ' + variantDetail.name + ': ' + variantDetail.option
	          }
	        })
	        // console.log('data', data)

	        if(this.availableItem.length !== 0){
	          if(includes(map(this.availableItem, 'id'), data.id)){
	            if(data.item_status[0].attributes.name == 'goods ready to be shipped'){
	              this.alreadyPacked++
	              const quantity = data.revised_quantity == null ? data.quantity : data.revised_quantity 
	              if(quantity > find(this.availableItem, aOI => aOI.id === data.id).qty){
	                this.products.push({
	                  text: text,
	                  value: data.id
	                })
	              }
	            }
	          }else{
	            // console.log('data.item_status[0].attributes', data.item_status[0].attributes)
	            if(data.item_status[0].attributes.name == 'goods ready to be shipped'){
	              this.alreadyPacked++
	              this.products.push({
	                text: text,
	                value: data.id
	              })
	            }
	          }
	        }else{
	          // console.log('Produk belum terpilih')
	          if(data.item_status[0].attributes.name == 'goods ready to be shipped'){
	            this.alreadyPacked++
	            this.products.push({
	              text: text,
	              value: data.id
	            })
	          }
	        }
	      }else{
	        if(this.availableItem.length !== 0){
	          if(includes(map(this.availableItem, 'id'), data.id)){
	            if(data.item_status[0].attributes.name == 'goods ready to be shipped'){
	              this.alreadyPacked++
	              const quantity = data.revised_quantity == null ? data.quantity : data.revised_quantity 
	              // console.log('quantity', quantity)
	              // console.log('quantitys', find(this.availableItem, aOI => aOI.id === data.id).qty)
	              if(quantity > find(this.availableItem, aOI => aOI.id === data.id).qty){
	                // console.log('Produk sudah terpilih dan jumlah produknya masih ada')
	                this.products.push({
	                  text: data.product_name,
	                  value: data.id
	                })
	              }
	            }
	          }else{
	            // console.log('Produk belum terpilih')
	            if(data.item_status[0].attributes.name == 'goods ready to be shipped'){
	              this.alreadyPacked++
	              this.products.push({
	                text: data.product_name,
	                value: data.id
	              })
	            }
	          }
	        }else{
	          // console.log('Produk belum terpilih')
	          if(data.item_status[0].attributes.name == 'goods ready to be shipped'){
	            this.alreadyPacked++
	            this.products.push({
	              text: data.product_name,
	              value: data.id
	            })
	          }
	        }
	      }
	    })

	    this.orderItems[0].products = this.products
	    this.orderItems[1].products = this.products
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.tableLoading = false
	  }
	}

	async getPackageListDetails() {
	  try {
	    const opts: any = {
	      params: {
	        include: 'packaging'
	      }
	    }
	    this.showLoading({text:  'Getting Package List Data' })
	    this.tableLoading = true
	    this.tableItemsPackageItem = []
	    this.dataImage = []

	    const response = await PackageService.getOnePackageList(
	      this.$route.params.id,
	      opts
	    )

	    this.dataPackage.name = response.data.attributes.name
	    this.dataPackage.courier_name = response.data.attributes.courier_name
	    this.dataPackage.tracking_code = response.data.attributes.tracking_code
	    this.dataPackage.tracking_code_courier = response.data.attributes.tracking_code_courier
	    this.dataPackage.last_mile_courier = response.data.attributes.last_mile_courier

	    if(!isEmpty(response.data.attributes.aftership)){
	      if(isEmpty(response.data.attributes.aftership.checkpoints)){
	        this.dataPackage.custom_tracking = true					
	      }
	    }else{
	      this.dataPackage.custom_tracking = true
	    }

	    this.orderId = response.data.attributes.order_id

	    if(!isEmpty(response.data.relationships.packaging)){
	      this.dataPackage.packaging = response.included.packaging
	      var dimens
	      this.totalCBM = 0
	      this.totalWeight = 0
	      this.totalNettWeight = 0
	      forEach(this.dataPackage.packaging, (dataPacking: any) => {
	        const orderItems = this.getOrderItem(dataPacking.id)
	        var orderItem = []
	        let images = []
	        orderItems.then(function(result: any) {
	          if(!isEmpty(result.data.relationships.items)){
	            forEach(result.included.items, (dataItems: any) => {
	              let text = dataItems.attributes.product_name
	              if(!isEmpty(dataItems.attributes.variants)){
	                forEach(dataItems.attributes.variants, (variantDetail: any) => {
	                  text = text + ' - ' + variantDetail.name + ': ' + variantDetail.option
	                })
	                orderItem.push({
	                  name: text,
	                  qty: dataItems.attributes.pivot.qty + ' ' + dataItems.attributes.pivot.qty_unit
	                })
	              }
	            })
	          }		

	          if(!isEmpty(result.data.relationships.images)){
	            forEach(result.included.images, (dataImages: any) => {
	              images.push(dataImages.attributes)
	            })
	          }
	        })

	        const data = dataPacking.attributes
	        const width = find(
	          data.dimensions,
	          dim => lowerCase(dim.label) === 'width'
	        ).value
	        const height = find(
	          data.dimensions,
	          dim => lowerCase(dim.label) === 'height'
	        ).value
	        const length = find(
	          data.dimensions,
	          dim => lowerCase(dim.label) === 'length'
	        ).value

	        dimens = 0
	        if(data.dimension_unit === 'meters'){
	          dimens = width*height*length
	        }else if(data.dimension_unit === 'inchs'){
	          dimens = (width/39.37)*(height/39.37)*(length/39.37)
	        }else if(data.dimension_unit === 'cms'){
	          dimens = (width/100)*(height/100)*(length/100)
	        }

	        this.totalCBM += Number(dimens * data.package_qty)
	        this.totalWeight += data.gross_weight * data.package_qty
	        this.totalNettWeight += data.net_weight * data.package_qty

	        const tempDataPacking = {
	          id: dataPacking.id,
	          marks: data.marks,
	          order_items: orderItem,
	          package_qty: data.package_qty,
	          package_type: data.package_type,
	          item_qty: data.item_qty,
	          net_weight: data.net_weight,
	          gross_weight: data.gross_weight,
	          dimension: width + ' x ' + height + ' x ' + length + ' ' + data.dimension_unit,
	          total_cbm: dimens,
	          images: images
	        }

	        this.tableItemsPackageItem.push(tempDataPacking)
	      })
	    }else{
	      this.dataPackage.packaging = null
	    }

	    const opts2: any = {
	      params: {
	        //   "page[num]": 1,
	        //   "page[limit]": 10,
	        //   sort: "-created_at",
	        include: 'packaging',
	        'filter[order_id][is]': this.orderId
	        //   page: this.tablePagination.page,
	        //   limit: this.tablePagination.rowsPerPage,
	        //   sortedBy: this.tablePagination.descending ? "desc" : "asc",
	        //   orderBy: this.tablePagination.sortBy,
	        //   include: "details.ref.images,details.ref.variation,user"
	      }
	    }
	    var availableOrderItem = []
	    this.availableItem = []
	    let allPromise = []

	    const response2 = await PackageService.getPackageLists(opts2)

	    forEach(response2.included.packaging, (dataPacking: any) => {
	      const orderItems = this.getOrderItem(dataPacking.id)
	      allPromise.push(orderItems)
	    })

	    await Promise.all(allPromise).then(response2 => {
	      forEach(response2, (result: any) => {
	        if(!isEmpty(result.data.relationships.items)){
	          forEach(result.included.items, (dataItems: any) => {
	            availableOrderItem.push({
	              id: dataItems.attributes.id,
	              qty: dataItems.attributes.pivot.qty*result.data.attributes.package_qty
	            })
	          })
	        }

	        if(!isEmpty(result.data.relationships.images)){
	          this.dataImage.push({
	            packing_id: result.data.id,
	            image: result.included.images
	          })
	        }
	      })		
	    })

	    this.availableItem = availableOrderItem

	    await this.getOrderDetails(response.data.attributes.order_id, availableOrderItem)
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.tableLoading = false
	    this.closeLoading()
	  }
	}

	async getOnePacking() {
	  try {
	    const opts: any = {
	      params: {
	        include: 'items,images'
	      }
	    }

	    const response: any = await PackageService.getOnePacking(
	      this.idUpdate,
	      opts
	    )

	    let width = find(
	      response.data.attributes.dimensions,
	      dim => lowerCase(dim.label) === 'width'
	    ).value
	    let height = find(
	      response.data.attributes.dimensions,
	      dim => lowerCase(dim.label) === 'height'
	    ).value
	    let length = find(
	      response.data.attributes.dimensions,
	      dim => lowerCase(dim.label) === 'length'
	    ).value

	    // console.log('product',this.products)

	    if(size(response.included.items) > 1){
	      this.orderItems = []
	      forEach(response.included.items, (dataItems: any) => {
	        let text = dataItems.attributes.product_name
	        if(!isEmpty(dataItems.attributes.variants)){
	          forEach(dataItems.attributes.variants, (variantDetail: any) => {
	            text = text + ' - ' + variantDetail.name + ': ' + variantDetail.option
	          })
	        }
	        // const product = [{
	        // 	text: text,
	        // 	value: dataItems.attributes.id
	        // }]
	        this.orderItems.push({
	          id: dataItems.attributes.id,
	          productQuantity: dataItems.attributes.pivot.qty,
	          productUnit: dataItems.attributes.pivot.qty_unit,
	          products: this.products,
	          placeholder: text
	        })
	      })
	      this.packagingData.marks = response.data.attributes.marks
	      this.packagingData.packageType = response.data.attributes.package_type
	      this.packagingData.nettWeight = response.data.attributes.net_weight
	      this.packagingData.grossWeight = response.data.attributes.gross_weight
	      this.packagingData.length = length
	      this.packagingData.width = width
	      this.packagingData.height = height
	      this.packagingData.dimensionUnit = response.data.attributes.dimension_unit
	      this.packagingData.packageQuantity = response.data.attributes.package_qty
	    }else{
	      forEach(response.included.items, (dataItem: any) => {
	        if(dataItem.attributes.unit_name === 'crt' || dataItem.attributes.unit_name === 'lsn' || 
					dataItem.attributes.unit_name === 'box' || dataItem.attributes.unit_name === 'pak' || 
					dataItem.attributes.unit_name === 'set' || dataItem.attributes.unit_name === 'grs') this.isBundle = true
	        else this.isBundle = false

	        let text = dataItem.attributes.product_name
	        if(!isEmpty(dataItem.attributes.variants)){
	          forEach(dataItem.attributes.variants, (variantDetail: any) => {
	            text = text + ' - ' + variantDetail.name + ': ' + variantDetail.option
	          })
	        }
	        this.packagingDataSingle.id = dataItem.attributes.id
	        this.packagingDataSingle.productQuantity = dataItem.attributes.pivot.qty
	        this.packagingDataSingle.productUnit = dataItem.attributes.pivot.qty_unit
	        this.packagingDataSingle.placeholder = text
	      })
	      this.packagingDataSingle.marks = response.data.attributes.marks
	      this.packagingDataSingle.packageType = response.data.attributes.package_type
	      this.packagingDataSingle.nettWeight = response.data.attributes.net_weight
	      this.packagingDataSingle.grossWeight = response.data.attributes.gross_weight
	      this.packagingDataSingle.length = length
	      this.packagingDataSingle.width = width
	      this.packagingDataSingle.height = height
	      this.packagingDataSingle.dimensionUnit = response.data.attributes.dimension_unit
	      this.packagingDataSingle.packageQuantity = response.data.attributes.package_qty

	      if(this.packagingDataSingle.id == ''){
	        this.moduloProductQuantity = 0
	      }
	      // console.log('idOrderItem', this.packagingDataSingle.id)
	      // console.log('this.moduloProductQuantity', this.moduloProductQuantity)
	      this.moduloProductQuantity = 0
	      // if (data.id === this.orderItems[index].id){					
	      // 	if(includes(map(this.availableItem, 'id'), data.id)){
	      // 		this.orderItems[index].productQuantity = data.quantity - find(this.availableItem, aI => aI.id === data.id).qty
	      // 	}else{
	      // 		this.orderItems[index].productQuantity = data.quantity
	      // 	}
	      // }
	      // console.log('this.dataDetails', this.dataDetails)
	      forEach(this.dataDetails, (dataItem: any) => {
	        const data = dataItem.attributes
	        if(data.id == this.packagingDataSingle.id){
	          if (!this.isBundle) {
	            const quantity = data.revised_quantity == null ? data.quantity : data.revised_quantity 
	            this.moduloProductQuantity = quantity - find(this.availableItem, aI => aI.id === data.id).qty
	          } else {
	            this.packagingDataSingle.qtyPackageBundleDefault = data.quantity
	            const quantity = data.revised_quantity == null ? (data.quantity * data.quantity_per_unit)  : data.revised_quantity 
	            this.moduloProductQuantity = quantity - find(this.availableItem, aI => aI.id === data.id).qty
	          }
	        }
	      })
	      this.defaultProductQuantity = this.moduloProductQuantity + (this.packagingDataSingle.productQuantity * this.packagingDataSingle.packageQuantity)
	      this.packagingDataSingle.qtyModuloTemp = this.moduloProductQuantity
	    }
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    // console.log('Modulo After Watch', this.moduloProductQuantity)
	    // this.getModuloEdit(this.packagingDataSingle.id)
	  }
	}
	
	onPackagingModalOpen() {
	  // const sumQuantityTable = sum(map(this.tableItemsPackageItem, "item_qty"))
	  // const sumQuantityOrder = sum(map(this.dataDetails, "attributes.quantity"))
	  // console.log('this.alreadyPacked', this.alreadyPacked)
	  // console.log('sumQuantityOrder', sumQuantityOrder)
	  // console.log('sumQuantityTable', sumQuantityTable)
	  // console.log('this.tableItemsPackageItem', this.tableItemsPackageItem)

	  if(this.alreadyPacked === 0){
	    this.showSnackbar({
	      text: 'All Order Items not goods ready to be shipped!',
	      color: 'red',
	      timeout: 3000
	    })
	    // }else if(sumQuantityTable > sumQuantityOrder || this.products.length === 0){
	  }else if(this.products.length === 0){
	    this.showSnackbar({
	      text: 'All Order Items have been packed!',
	      color: 'red',
	      timeout: 3000
	    })
	  }else{
	    this.packagingModal.open = true
	  }
	}

	onPackagingModalClose(type) {
	  // if(type === 3){
	  this.packagingDataSingle = {
	    id: '',
	    productQuantity: '',
	    productUnit: '',
	    marks: '',
	    packageType: '',
	    nettWeight: '',
	    grossWeight: '',
	    length: '',
	    width: '',
	    height: '',
	    dimensionUnit: '',
	    packageQuantity: '',
	    placeholder: ''
	  }
	  this.packagingModalEdit.open = false
	  this.moduloProductQuantity = 0
	  // }else if(type === 4){
	  this.orderItems = [{
	    id: '',
	    productQuantity: '',
	    productUnit: '',
	    products: this.products,
	    placeholder: ''
	  },
	  {
	    id: '',
	    productQuantity: '',
	    productUnit: '',
	    products: this.products
	  }]
	  this.packagingData = {
	    orderItems: null,
	    marks: '',
	    packageType: '',
	    nettWeight: '',
	    grossWeight: '',
	    length: '',
	    width: '',
	    height: '',
	    dimensionUnit: '',
	    packageQuantity: 1
	  }
	  // this.packagingModalEdit.open = false
	  // }else if(type === 5){
	  // this.packagingDataSingle = {
	  // 	id: '',
	  // 	productQuantity: '',
	  // 	productUnit: '',
	  // 	marks: '',
	  // 	packageType: '',
	  // 	nettWeight: '',
	  // 	grossWeight: '',
	  // 	length: '',
	  // 	width: '',
	  // 	height: '',
	  // 	dimensionUnit: '',
	  // 	packageQuantity: '',
	  // 	placeholder: ''
	  // }
	  this.packagingModal.open = false
	  // this.moduloProductQuantity = 0
	  // }else if(type === 6){
	  // this.orderItems = [{
	  // 	id: '',
	  // 	productQuantity: '',
	  // 	productUnit: '',
	  // 	products: this.products,
	  // 	placeholder: ''
	  // },
	  // {
	  // 	id: '',
	  // 	productQuantity: '',
	  // 	productUnit: '',
	  // 	products: this.products,
	  // 	placeholder: ''
	  // }]
	  // this.packagingData = {
	  // 	orderItems: null,
	  // 	marks: '',
	  // 	packageType: '',
	  // 	nettWeight: '',
	  // 	grossWeight: '',
	  // 	length: '',
	  // 	width: '',
	  // 	height: '',
	  // 	dimensionUnit: '',
	  // 	packageQuantity: 1,
	  // }
	  // this.packagingModal.open = false
	  // }else{
	  // this.packagingModal.open = false
	  // }
	  this.idUpdate = null
	  this.type = null
	  this.$validator.errors.clear()
	  this.$validator.reset()
	}

	async onPackagingModalSave(type, edit) {
	  try {
	    this.showLoading({ text: 'Saving...' })
	    if(type === 1){
	      const valid = await this.$validator.validateAll('addPacking')
	      if(valid){
	        if(edit === 2){
	          const payload = {
	            'marks': this.packagingDataSingle.marks,
	            'package_qty': this.packagingDataSingle.packageQuantity,
	            'package_type': this.packagingDataSingle.packageType,
	            'item_qty': this.packagingDataSingle.productQuantity*this.packagingDataSingle.packageQuantity,
	            'item_qty_unit': this.packagingDataSingle.productUnit,
	            'net_weight': this.packagingDataSingle.nettWeight,
	            'gross_weight': this.packagingDataSingle.grossWeight,
	            'dimension': [
	              {
	                'label': 'length',
	                'value': this.packagingDataSingle.length
	              },
	              {
	                'label': 'width',
	                'value': this.packagingDataSingle.width
	              },
	              {
	                'label': 'height',
	                'value': this.packagingDataSingle.height
	              }
	            ],
	            'dimension_unit': this.packagingDataSingle.dimensionUnit,
	            'order_items':[
	              {
	                'item_id': this.packagingDataSingle.id,
	                'qty': this.packagingDataSingle.productQuantity,
	                'qty_unit': this.packagingDataSingle.productUnit
	              }
	            ]
	          }
	
	          const response = await PackageService.updatePacking(this.idUpdate,payload)
	          if(response.success){
	            this.getPackageListDetails()
	            this.onPackagingModalClose(3)
		
	            this.showSnackbar({
	              text: 'Updated Successfully!',
	              color: 'green',
	              timeout: 1500
	            })
	          }else{
	            this.showSnackbar({
	              text: 'Update Failed!',
	              color: 'red',
	              timeout: 2000
	            })
	          }
	        }else{
	          const payload = {
	            'order_id': this.orderId,
	            'package_id': this.$route.params.id,
	            'marks': this.packagingDataSingle.marks,
	            'package_qty': this.packagingDataSingle.packageQuantity,
	            'package_type': this.packagingDataSingle.packageType,
	            'item_qty': this.packagingDataSingle.productQuantity*this.packagingDataSingle.packageQuantity,
	            'item_qty_unit': this.packagingDataSingle.productUnit,
	            'net_weight': this.packagingDataSingle.nettWeight,
	            'gross_weight': this.packagingDataSingle.grossWeight,
	            'dimension': [
	              {
	                'label': 'length',
	                'value': this.packagingDataSingle.length
	              },
	              {
	                'label': 'width',
	                'value': this.packagingDataSingle.width
	              },
	              {
	                'label': 'height',
	                'value': this.packagingDataSingle.height
	              }
	            ],
	            'dimension_unit': this.packagingDataSingle.dimensionUnit,
	            'order_items':[
	              {
	                'item_id': this.packagingDataSingle.id,
	                'qty': this.packagingDataSingle.productQuantity,
	                'qty_unit': this.packagingDataSingle.productUnit
	              }
	            ]
	          }
	
	          const response = await PackageService.createNewPacking(payload)
	          if(response.data.success){
	            this.getPackageListDetails()
	            this.onPackagingModalClose(5)
		
	            this.showSnackbar({
	              text: 'Saved Successfully!',
	              color: 'green',
	              timeout: 1500
	            })
	          }else{
	            this.showSnackbar({
	              text: 'Saved Failed!',
	              color: 'red',
	              timeout: 2000
	            })
	          }
	        }
	      }else{
	        this.showSnackbar({
	          text: 'Please check all fields requirements',
	          color: 'red',
	          timeout: 2000
	        })
	      }
	    }else{
	      let orderPayload
	      let totalQuantity
	      var idSelected = []
	      var uniqIdSelected = []
				
	      idSelected = map(this.orderItems, 'id')
	      uniqIdSelected = uniq(idSelected)

	      const valid = await this.$validator.validateAll('addPackingMultiple')
	      if(valid){
	        if(idSelected.length !== uniqIdSelected.length){
	          this.showSnackbar({
	            text: 'Can\'t Duplicate Item!!!',
	            color: 'red',
	            timeout: 3000
	          })
	        }else{
	          if(edit === 2){
	            orderPayload = []
	            totalQuantity = 0
		
	            forEach(this.orderItems, (payloadItems: any) => {
	              totalQuantity = totalQuantity+payloadItems.productQuantity
		
	              const itemData = {
	                'item_id': payloadItems.id,
	                'qty': payloadItems.productQuantity,
	                'qty_unit': payloadItems.productUnit
	              }
		
	              orderPayload.push(itemData)
	            })
		
	            const payload = {
	              'marks': this.packagingData.marks,
	              'package_qty': this.packagingData.packageQuantity,
	              'package_type': this.packagingData.packageType,
	              'item_qty': totalQuantity,
	              'item_qty_unit': 'Default',
	              'net_weight': this.packagingData.nettWeight,
	              'gross_weight': this.packagingData.grossWeight,
	              'dimension': [
	                {
	                  'label': 'length',
	                  'value': this.packagingData.length
	                },
	                {
	                  'label': 'width',
	                  'value': this.packagingData.width
	                },
	                {
	                  'label': 'height',
	                  'value': this.packagingData.height
	                }
	              ],
	              'dimension_unit': this.packagingData.dimensionUnit,
	              'order_items': orderPayload
	            }
		
	            const response = await PackageService.updatePacking(this.idUpdate, payload)
	            if(response.success){
	              this.getPackageListDetails()
	              this.onPackagingModalClose(4)
		
	              this.showSnackbar({
	                text: 'Saved Successfully!',
	                color: 'green',
	                timeout: 1500
	              })
	            }else{
	              this.showSnackbar({
	                text: 'Saved Failed!',
	                color: 'red',
	                timeout: 2000
	              })
	            }
	          }else{
	            orderPayload = []
	            totalQuantity = 0
		
	            forEach(this.orderItems, (payloadItems: any) => {
	              totalQuantity = totalQuantity+payloadItems.productQuantity
		
	              const itemData = {
	                'item_id': payloadItems.id,
	                'qty': payloadItems.productQuantity,
	                'qty_unit': payloadItems.productUnit
	              }
		
	              orderPayload.push(itemData)
	            })
		
	            const payload = {
	              'order_id': this.orderId,
	              'package_id': this.$route.params.id,
	              'marks': this.packagingData.marks,
	              'package_qty': this.packagingData.packageQuantity,
	              'package_type': this.packagingData.packageType,
	              'item_qty': totalQuantity,
	              'item_qty_unit': 'Default',
	              'net_weight': this.packagingData.nettWeight,
	              'gross_weight': this.packagingData.grossWeight,
	              'dimension': [
	                {
	                  'label': 'length',
	                  'value': this.packagingData.length
	                },
	                {
	                  'label': 'width',
	                  'value': this.packagingData.width
	                },
	                {
	                  'label': 'height',
	                  'value': this.packagingData.height
	                }
	              ],
	              'dimension_unit': this.packagingData.dimensionUnit,
	              'order_items': orderPayload
	            }
		
	            const response = await PackageService.createNewPacking(payload)
	            // console.log('response', response)
	            if(response.data.success){
	              this.getPackageListDetails()
	              this.onPackagingModalClose(6)
		
	              this.showSnackbar({
	                text: 'Saved Successfully!',
	                color: 'green',
	                timeout: 1500
	              })
	            }else{
	              this.showSnackbar({
	                text: 'Saved Failed!',
	                color: 'red',
	                timeout: 2000
	              })
	            }
	          }
	        }
	      }else{
	        this.showSnackbar({
	          text: 'Please check all fields requirements',
	          color: 'red',
	          timeout: 2000
	        })
	      }
	    }
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.closeLoading()
	  }
	}

	addItem(){
	  var blankSelected = 0
	  var idSelected = []
	  var uniqIdSelected = []
		
	  idSelected = map(this.orderItems, 'id')
	  uniqIdSelected = uniq(idSelected)

	  forEach(this.orderItems, (dataItem: any) => {
	    if(dataItem.id === ''){
	      blankSelected++
	    }
	  })

	  if(blankSelected > 0){
	    this.showSnackbar({
	      text: 'Please Choose Order Items!',
	      color: 'red',
	      timeout: 3000
	    })
	  }else if(idSelected.length !== uniqIdSelected.length){
	    this.showSnackbar({
	      text: 'Can\'t Duplicate Item!',
	      color: 'red',
	      timeout: 3000
	    })
	  }else if(this.orderItems.length === this.products.length){
	    this.showSnackbar({
	      text: 'All of Items has been selected!',
	      color: 'red',
	      timeout: 3000
	    })
	  }else{
	    this.orderItems.push({
	      id: '',
	      productQuantity: '',
	      productUnit: '',
	      products: this.products,
	      placeholder: ''
	    })
	  }
	}

	removeItem(itemIndex) {
	  this.orderItems.splice(itemIndex, 1)
	}

	onChangeProducts(index){
	  // console.log('Data details', this.dataDetails)
	  // console.log('Data', this.availableItem)
	  // console.log("this.orderItems[index]", this.orderItems[index])
	  if(this.orderItems[index].id === undefined){
	    this.orderItems[index].productQuantity = ''
	  }else{
	    forEach(this.dataDetails, (dataItem: any) => {
	      const data = dataItem.attributes

	      if (data.id === this.orderItems[index].id){
	        const quantity = data.revised_quantity == null ? data.quantity : data.revised_quantity 
	        // console.log("quantity", quantity)	
	        if(includes(map(this.availableItem, 'id'), data.id)){
	          // console.log("find(this.availableItem, aI => aI.id === data.id).qty", find(this.availableItem, aI => aI.id === data.id))	
	          if(this.orderItems[index].productQuantity == ''){
	            // console.log('Masuk')
	            this.orderItems[index].productQuantity = quantity - find(this.availableItem, aI => aI.id === data.id).qty
	          }else{
	            // console.log('Masuki')
	            this.orderItems[index].productQuantity = quantity
	          }
	        }else{
	          this.orderItems[index].productQuantity = quantity
	        }
	      }
	    })			
	  }
	}

	onChangeProductSingle(){
	  this.moduloProductQuantity = 0
	  this.packagingDataSingle = {
	    ...this.packagingDataSingle,
	    productQuantity : null,
	    packageQuantity : null,
	    nettWeight : null,
	    width : null,
	    height : null,
	    length : null,
	    qtyModuloTemp: null,
	    dimensionUnit: null,
	    productUnit: null,
	    qtyPackageBundleDefault: null
	  }
	  this.$validator.errors.clear()
	  this.$validator.reset()
	  forEach(this.dataDetails, (dataItem: any) => {
	    const data = dataItem.attributes
	    this.dataSelectedOrderItem = data
	    if(data.id == this.packagingDataSingle.id){
	      if(data.unit_name === 'crt' || data.unit_name === 'lsn' || data.unit_name === 'box' || data.unit_name === 'pak' || data.unit_name === 'set' || data.unit_name === 'grs') {
	        this.isBundle = true

	        this.packagingDataSingle = {
	          ...this.packagingDataSingle,
	          productQuantity : data.quantity_per_unit,
	          packageQuantity : data.quantity,
	          nettWeight : data.product.attributes.weight,
	          dimensionUnit: data.product.attributes.dimension_unit,
	          productUnit: data.base_unit_name,
	          packageType: data.unit_name
	        }
	        this.packagingDataSingle.width = find(
	          data.product.attributes.dimensions,
	          dim => lowerCase(dim.label) === 'width'
	        ).value
	        this.packagingDataSingle.height = find(
	          data.product.attributes.dimensions,
	          dim => lowerCase(dim.label) === 'height'
	        ).value
	        this.packagingDataSingle.length = find(
	          data.product.attributes.dimensions,
	          dim => lowerCase(dim.label) === 'length'
	        ).value

	        if (data.product.attributes.dimension_unit === 'cm') this.packagingDataSingle.dimensionUnit = 'cms'
	        else if (data.product.attributes.dimension_unit === 'm') this.packagingDataSingle.dimensionUnit = 'meters'
	        else if (data.product.attributes.dimension_unit === 'inch') this.packagingDataSingle.dimensionUnit = 'inchs'
	      } else {
	        this.isBundle = false

	        this.packagingDataSingle = {
	          ...this.packagingDataSingle,
	          nettWeight : data.product.attributes.weight,
	          dimensionUnit: data.product.attributes.dimension_unit,
	          productUnit: data.base_unit_name
	        }
					
	        this.packagingDataSingle.width = find(
	          data.product.attributes.dimensions,
	          dim => lowerCase(dim.label) === 'width'
	        ).value
	        this.packagingDataSingle.height = find(
	          data.product.attributes.dimensions,
	          dim => lowerCase(dim.label) === 'height'
	        ).value
	        this.packagingDataSingle.length = find(
	          data.product.attributes.dimensions,
	          dim => lowerCase(dim.label) === 'length'
	        ).value

	        if (data.product.attributes.dimension_unit === 'cm') this.packagingDataSingle.dimensionUnit = 'cms'
	        else if (data.product.attributes.dimension_unit === 'm') this.packagingDataSingle.dimensionUnit = 'meters'
	        else if (data.product.attributes.dimension_unit === 'inch') this.packagingDataSingle.dimensionUnit = 'inchs'
	      }
				
	      if (!this.isBundle) {
	        this.quantityValue = data.revised_quantity == null ? data.quantity : data.revised_quantity 
	      } else {
	        this.packagingDataSingle.qtyPackageBundleDefault = data.quantity
	        this.quantityValue = data.revised_quantity == null ? (data.quantity * data.quantity_per_unit) : data.revised_quantity 
	      }

	      this.firstGet = true
	      // this.quantityProduct = quantity
	      if(this.availableItem.length !== 0){
	        if(includes(map(this.availableItem, 'id'), data.id)){
	          this.moduloProductQuantity = this.moduloProductQuantity + this.quantityValue  - find(this.availableItem, aI => aI.id === data.id).qty
	        }else{
	          this.moduloProductQuantity = this.moduloProductQuantity + this.quantityValue 
	        }
	      }else{
	        this.moduloProductQuantity = this.moduloProductQuantity + this.quantityValue 
	      }
	      this.packagingDataSingle.qtyModuloTemp = this.moduloProductQuantity
	    }
	  })
	  this.defaultProductQuantity = this.moduloProductQuantity
	}

	getModuloEdit(){
	  // console.log('this.moduloProductQuantity', this.moduloProductQuantity)
	  // console.log('this.defaultProductQuantity', this.defaultProductQuantity)
	  this.moduloProductQuantity = this.defaultProductQuantity
	  // console.log('this.moduloProductQuantity', this.moduloProductQuantity)
	  if(this.packagingDataSingle.productQuantity !== '' && this.packagingDataSingle.packageQuantity !== ''){
	    if(this.packagingDataSingle.productQuantity == 0 || this.packagingDataSingle.packageQuantity == 0){
	      this.moduloProductQuantity = this.defaultProductQuantity
	    }else{
	      this.moduloProductQuantity = this.defaultProductQuantity - (this.packagingDataSingle.productQuantity * this.packagingDataSingle.packageQuantity)
	    }
	  }else{
	    this.moduloProductQuantity = this.defaultProductQuantity
	  }
	  // console.log('Bawah', this.moduloProductQuantity)
	  // if(this.packagingDataSingle.id == ''){
	  // 	this.moduloProductQuantity = 0
	  // }
	  // console.log('idOrderItem', idOrderItem)
	  // console.log('this.moduloProductQuantity', this.moduloProductQuantity)
	  // this.moduloProductQuantity = 0
	  // forEach(this.dataDetails, (dataItem: any) => {
	  // 	const data = dataItem.attributes
	  // 	if(data.id == idOrderItem){
	  // 		this.moduloProductQuantity = this.moduloProductQuantity + data.quantity
	  // 	}
	  // })
	  // this.defaultProductQuantity = this.moduloProductQuantity
	}

	async onPackingUpdate(index){
	  let param = index.split(',')
	  this.idUpdate = param[0]
	  this.type = (Number(param[1]) > 1) ? 2 : 1
	  await this.getOnePacking()
	  this.packagingModalEdit.open = true
	}

	onPackingDeleted(index){
	  this.packingDeleteModal.open = true
	  this.idDelete = index
	}

	onPackingDeletedClose(){
	  this.packingDeleteModal.open = false
	  this.idDelete = null
	}
	
	async onPackingDeletedSave(){
	  try {
	    this.showLoading({ text: 'Deleting...' })	
	    const response = await PackageService.deletePacking(this.idDelete)
	    if(response.data.success){
	      this.getPackageListDetails()

	      this.showSnackbar({
	        text: 'Deleted Successfully!',
	        color: 'teal',
	        timeout: 1500
	      })
	    }else{
	      this.showSnackbar({
	        text: 'Delete Failed!',
	        color: 'red',
	        timeout: 2000
	      })
	    }

	    this.idDelete = null
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.closeLoading()
	  }

	  this.packingDeleteModal.open = false
	}

	onTrackingStatusAddModalOpen(props:any){
	  this.trackingStatusAddModal.open = true
	  // if(item != null){
	  // 	this.isEditTracking = true
	  // 	const updateTrackingStatus: any = find(this.tableItemsTrackingItem, (trackingItem: any) => trackingItem.id_tracking_status === item)
	  // 	this.addTrackingStatus.valueStatus = Number(updateTrackingStatus.id_tracking_status)
	  // 	this.addTrackingStatus.newTrackingStatus = Number(updateTrackingStatus.id_tracking_status)
	  // 	this.addTrackingStatus.trackingStatusDate.date = updateTrackingStatus.date.split(' ')[0]
	  // 	//2019-12-12 00:00:00 - Split for Date Only [0] and Time Only [1]
	  // 	this.addTrackingStatus.trackingStatusDate.dateFormatted = this.toFullDate(
	  // 		updateTrackingStatus.date.split(' ')[0]
	  // 	)
	  // 	this.addTrackingStatus.comment = updateTrackingStatus.comment
	  // 	this.addTrackingStatus.trackingStatusTime.time = updateTrackingStatus.date.split(' ')[1].slice(0, 5)
	  // 	//Slice to get time in hours and minutes only
	  // 	// console.log('date', updateTrackingStatus.date.split(' ')[1].slice(0, 5))
	  // 	// console.log('items', this.addTrackingStatus.trackingStatusItems)
	  // 	// console.log('itemss', this.tableItemsTrackingItem)
	  // }else{
	  // 	this.isEditTracking = false
	  // }
	  // this.trackingStatusAddModal.open = true
	  if(props != null){
	    this.selectedEditId = props.item.id
	    this.isEditTracking = true
	    // const updateTrackingStatus: any = find(this.tableItemsTrackingItem, (trackingItem:any) => trackingItem.id === index)
	    // console.log(updateTrackingStatus, 'update tracking');
	    // console.log(this.tableItemsTrackingItem, 'itemsnya tbl tracking')
	    this.addTrackingStatus.valueStatus = Number(props.item.status_id)
	    this.addTrackingStatus.newTrackingStatus = Number(props.item.status_id)
	    this.addTrackingStatus.trackingStatusDate.date = props.item.date.split(' ')[0]
	    //2019-12-12 00:00:00 - Split for Date Only [0] and Time Only [1]
	    this.addTrackingStatus.trackingStatusDate.dateFormatted = this.toFullDate(
	      props.item.date.split(' ')[0]
	    )
	    this.addTrackingStatus.trackingStatusTime.time = props.item.date.split(' ')[1].slice(0, 5)
	    this.addTrackingStatus.comment = props.item.comment
	  }else{
	    this.isEditTracking = false
	  }
	}

	onAddTrackingStatusModalClosed(){
	  this.$validator.errors.clear()
	  this.$validator.reset()
	  this.addTrackingStatus.newTrackingStatus 				= null
	  this.addTrackingStatus.valueStatus		 				= null
	  this.addTrackingStatus.trackingStatusDate.date 			= null
	  this.addTrackingStatus.trackingStatusTime.time 			= null
	  this.addTrackingStatus.trackingStatusDate.dateFormatted = null
	  this.addTrackingStatus.comment 							= null
	  this.trackingStatusAddModal.open 						= false
	}

	async getTrackingStatusList() {
	  try {
	    const opts: any = {
	      params: {
	        include: 'packaging'
	      }
	    }
	    this.tableLoading = true
	    this.tableItemsTrackingItem = []
	    const response = await PackageService.getOnePackageList(
	      this.$route.params.id,
	      opts
	    )
	    // console.log('getone pl', response)
	    // console.log(response.data.attributes.history_status)
	    // console.log(response.data.relationships.status, 'status')
	    // const response_1 = response.data.relationships.status
	    // for (const data_response_1 of response_1) {
	    // 	const status_1: any = {
	    // 		id_tracking_status	: data_response_1.id
	    // 	}
	    for (const historyStatus of response.data.attributes.history_status){
	      // const tracking: any = {
	      // 	trackingStatus : historyStatus
	      // }
	      // const item: any = {
	      // 	id_tracking_status	: tracking.trackingStatus.id,
	      // 	status_id			: tracking.trackingStatus.status_id,
	      // 	name				: this.toStartCase(tracking.trackingStatus.name),
	      // 	comment				: tracking.trackingStatus.comment,
	      // 	date				: tracking.trackingStatus.date
	      // }
	      this.tableItemsTrackingItem.push(historyStatus)
	    }
	    // }
	    // console.log(this.tableItemsTrackingItem, 'item tracking');
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.tableLoading = false
	  }
	}

	async getTrackingStatus() {
	  try {
	    const opts: any = {
	      params: {
	        'filter[status_type][is]': 3
	      }
	    }

	    const response = await StatusService.getOrderStatus(opts)

	    const trackingStatus = response.data

	    for (const dataStatus of trackingStatus) {
	      const status: any = {
	        text: this.toStartCase(dataStatus.attributes.name),
	        value: dataStatus.attributes.id
	      }
	      this.addTrackingStatus.trackingStatusItems.push(status)
	    }

	  } catch (error) {
	    this.catchHandler(error)
	  }
	}

	async onAddTrackingStatusModalSave() {
	  try {
	    const valid = await this.$validator.validateAll('addTrackingStatus')
	    if (valid) {
	      this.showLoading({ text: 'Saving...' })
	      var resp 
	      if(!this.isEditTracking){
	        const tracking_status: any = {
	          status_id	: this.addTrackingStatus.newTrackingStatus,
	          comment		: this.addTrackingStatus.comment,
	          date		: this.addTrackingStatus.trackingStatusDate.date + ' ' + this.addTrackingStatus.trackingStatusTime.time + ':00'
	        }
	        resp = await PackageService.createNewTrackingStatus(this.$route.params.id, tracking_status)
	      }else{
	        const tracking_status: any = {
	          // old_status_id	: this.addTrackingStatus.valueStatus,
	          // new_status_id	: this.addTrackingStatus.newTrackingStatus,
	          status_id		: this.addTrackingStatus.newTrackingStatus,
	          // statusable_id	: this.addTrackingStatus.statusable_id,
	          date			: this.addTrackingStatus.trackingStatusDate.date + ' ' + this.addTrackingStatus.trackingStatusTime.time + ':00',
	          comment			: this.addTrackingStatus.comment
	        }
	        resp = await PackageService.updateTrackingStatus(this.selectedEditId, tracking_status)
	      }
	      if(resp.data.success){
	        this.getTrackingStatusList()	
	        this.showSnackbar({
	          text: 'Saved Successfully!',
	          color: 'green',
	          timeout: 1500
	        })
	      }else{
	        this.showSnackbar({
	          text: 'Saved Failed!',
	          color: 'red',
	          timeout: 2000
	        })
	      }
	      this.onAddTrackingStatusModalClosed()
	    } else {
	      this.showSnackbar({
	        text: 'Please check all fields requirements',
	        color: 'red',
	        timeout: 2000
	      })
	    }
	  }catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.closeLoading()			
	  }
	}

	// onNewAddTrackingStatus() {
	// 	this.addTrackingStatus.valueStatus = this.addTrackingStatus.newTrackingStatus
	// }

	// onTrackingStatusDateInput(event) {
	// 	this.addTrackingStatus.trackingStatusDate.tempDate = event
	// }

	onTrackingStatusDateCancel() {
	  this.addTrackingStatus.trackingStatusDate.open = false
	}

	onTrackingStatusDateDone() {
	  // this.addTrackingStatus.trackingStatusDate.date = this.addTrackingStatus.trackingStatusDate.tempDate
	  this.addTrackingStatus.trackingStatusDate.dateFormatted = this.toFullDate(
	    this.addTrackingStatus.trackingStatusDate.date
	  )
	  this.addTrackingStatus.trackingStatusDate.open = false
	}
	// onTrackingStatusTimeInput(event) {
	// 	this.addTrackingStatus.trackingStatusTime.tempTime = event
	// }

	onTrackingStatusTimeCancel() {
	  this.addTrackingStatus.trackingStatusTime.open = false
	}

	onTrackingStatusTimeDone() {
	  // this.addTrackingStatus.trackingStatusTime.time = this.addTrackingStatus.trackingStatusDate.tempTime
	  // this.addTrackingStatus.trackingStatusTime.timeFormatted = this.addTrackingStatus.tempTime
	  this.addTrackingStatus.trackingStatusTime.open = false
	}

	onTrackingStatusDeleted(props){
	  // console.log(props, 'index delete')
	  this.trackingStatusDeleteModal.open = true
	  this.idDeleteTrackingStatus = props.item.id
	  // console.log(this.idDeleteTrackingStatus)
	}

	onTrackingStatusDeleteClose(){
	  this.trackingStatusDeleteModal.open = false
	  this.idDeleteTrackingStatus = null
	}
	
	async onTrackingStatusDeletedSave(){
	  try {
	    this.showLoading({ text: 'Deleting...' })	
	    const payload: any = {
	      id : this.idDeleteTrackingStatus
	    }
	    const response = await PackageService.deleteTrackingStatus(payload.id)
	    if(response.data.success){
	      this.getTrackingStatusList()	
	      this.showSnackbar({
	        text: 'Saved Successfully!',
	        color: 'green',
	        timeout: 1500
	      })
	    }else{
	      this.showSnackbar({
	        text: 'Saved Failed!',
	        color: 'red',
	        timeout: 2000
	      })
	    }
	    this.onTrackingStatusDeleteClose()
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.closeLoading()
	  }
	}

	onAddPackingImage(id){
	  this.showModalAddImage = true
	  this.updateImageId = id
	  // console.log('this.dataImage', this.dataImage)
	  setTimeout(() => {
	    forEach(this.dataImage, (packingImage, index) => {
	      let img = ''
	      // let allImg = ''
	      if(packingImage.packing_id == id){
	        forEach(packingImage.image, (image, index) => {
	          img = '<img src=\'' + image.attributes.source + '\' style=\'max-width:100% !important;vertical-align:middle;margin-bottom:10px;\' />'
	          // allImg += img
	          let mockFile: any = {
	            name: `${image.attributes.id}_${
	              image.attributes.source
	                .split('/')
	                .pop()
	                .split('#')[0]
	                .split('?')[0]
	            }`,
	            id: image.attributes.id,
	            accepted: true,
	            kind: 'image',
	            status: 'fetched',
	            type: 'image/jpeg',
	            upload: {
	              filename: image.attributes.source
	                .split('/')
	                .pop()
	                .split('#')[0]
	                .split('?')[0]
	            }
	            // default: response.included.images[image.id].attributes.is_default ? true : false
	          }
						;(this.$refs as any).images.manuallyAddFile(
	            mockFile,
	            image.attributes.source,
	            () => {
	              // console.log('Callback')
	            },
	            null,
	            {
	              dontSubstractMaxFiles: true,
	              addToFiles: true
	            }
	          )
	          ;(this.$refs as any).images.dropzone.emit('thumbnail', mockFile, image.attributes.source)
	        })
	      }
			  })
	  }, 1000)
	  // console.log('this.dataImages', this.dataImage)
	}
		
	// Image Viewer
	$viewer: any
	inited(viewer) {
	  this.$viewer = viewer
	}
	show() {
	  // this.dataViewer = map(images, 'source')
	  this.$viewer.show()
	}
	
	imageAdded(file) {
	  if (file.id === undefined) {
		  file.id = file.upload.uuid
	  }
	}
	imageAddedManually(file) {
	  // console.log(file)
	}
	imageAddedError(file) {
	  if (file.type != 'image/jpg' && file.type != 'image/jpeg' && file.type != 'image/png') {
		  ; (this.$refs as any).images.removeFile(file)
		  this.showSnackbar({ text: 'Error! Not an image file(color: s)', color: 'red' })
	  } else if (file.size > this.options.maxFileSizeInMB * 1024 * 1024) {
		  ; (this.$refs as any).images.removeFile(file)
		  this.showSnackbar({
	      text: `Error! Image(s) size is too large. Max ${this.options.maxFileSizeInMB} MB`,
	      color: 'red',
	      timeout: 5000
		  })
	  }
	  // this.error()
	}
	imageDuplicate(file) {
	  this.showSnackbar({ text: 'Image is already added', color: 'orange', timeout: 2000 })
	}
	imageRemoved(file: any, error: any, xhr: any) {
	  // console.log('file', file)
	  if (file.status === 'fetched') this.deletedImages.push(file.id)
	}
	beforeSendingImage(file, xhr, formData) {
	  formData.append('images_for', this.updateImageId)
	  formData.append('type', 'image')
	}
	beforeSendingImageMultiple(file, xhr, formData) {
	  formData.append('images_for', this.updateImageId)
	  formData.append('type', 'image')
	}
	imageUploadSuccess(file, response) {
	  if ((this.$refs as any).images.getUploadingFiles().length <= 0) {}
	}
	imageUploadMultipleSuccess(files, response) {
	  if ((this.$refs as any).images.getUploadingFiles().length <= 0) {
	  }
	}
	imageUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) { }
	savePackageImage(){
	  // Send Images
	  // console.log((this.$refs as any).images)
	  if(!isEmpty(this.deletedImages)){
	    this.deleteImages()
	    if ((this.$refs as any).images.getQueuedFiles().length > 0) {
	      // this.closeLoading()
	      this.showLoading({ text: 'Uploading Images...' })
	      ;(this.$refs as any).images.setOption('url', `${baseUrl}api/v2/packing-list/update-bulk`)
	      ;(this.$refs as any).images.processQueue()	
	    }
	    this.done()
	  }else{
	    if ((this.$refs as any).images.getQueuedFiles().length > 0) {
	      this.showLoading({ text: 'Uploading Images...' })
	      ;(this.$refs as any).images.setOption('url', `${baseUrl}api/v2/packing-list/update-bulk`)
	      ;(this.$refs as any).images.processQueue()	
	      this.done()
	    }else{
	      this.closeLoading()
	      ; (this.$refs as any).images.dropzone.getAcceptedFiles().forEach((file) =>
	      {
	        ; (this.$refs as any).images.removeFile(file)
	      })
	    }
	  }
	  this.showModalAddImage = false
	  this.deletedImages = []
	}

	deleteImages(){
	  let delData = []
	  forEach(this.deletedImages, (delImage: any) => {
	    delData.push({
	      type: 'images',
	      action: 'delete',
	      id: delImage
	    })
	  })

	  const payload = {
	    data: [
	      {
	        id: this.updateImageId,
	        relationships: delData
	      }
	    ]
	  }
	  PackageService.deleteImage(payload)
	}

	done() {
	  this.showSnackbar({ text: 'Image Successfully Saved!', color: 'green', timeout: 2000 })
	  setTimeout(() => {
		  	this.closeLoading()
	 		window.location.reload()
	  }, 4000)
	  }
}
