import BaseService from '@/services/BaseService'

class PackageService extends BaseService {
  constructor() {
    super()
  }
  getPackageLists(opts = {}) {
    this.endPoint = 'api/v2/package'
    return this.get(opts).then(response => {
      return response.data
    })
  }
  getOnePackageList(id: any, params = {}) {
    this.endPoint = 'api/v2/package'
    return this.getOne(id, params).then(response => {
      return response.data
    })
  }
  getOnePacking(id: any, params = {}) {
    this.endPoint = 'api/v2/packing-list'
    return this.getOne(id, params).then(response => {
      return response.data
    })
  }
  createNewPackageList(data: any) {
    this.endPoint = 'api/v2/package'
    return this.post(data).then(response => {
      return response
    })
  }
  createNewPacking(data: any) {
    this.endPoint = 'api/v2/packing-list'
    return this.post(data).then(response => {
      return response
    })
  }
  updatePackageList(id: any, data: any, params = {}) {
    this.endPoint = 'api/v2/package'
    return this.putOne(id, data, params).then(response => {
      return response.data
    })
  }
  deletePackageList(id: any) {
    this.endPoint = 'api/v2/package'
    return this.delete(id).then(response => {
      return response
    })
  }
  updatePacking(id: any, data: any, params = {}) {
    this.endPoint = 'api/v2/packing-list'
    return this.putOne(id, data, params).then(response => {
      return response.data
    })
  }
  deletePacking(id: any) {
    this.endPoint = 'api/v2/packing-list'
    return this.delete(id).then(response => {
      return response
    })
  }

  deleteImage(payload) {
    this.endPoint = 'api/v2/packing-list/update-bulk'
    return this.post(payload).then(response => {
      return response
    })
  }

  deleteImagePackage(payload) {
    this.endPoint = 'api/v2/package/update-bulk'
    return this.post(payload).then(response => {
      return response
    })
  }

  // Add Tracking Status
  createNewTrackingStatus(packageId, payload = {}) {
    this.endPoint = `api/v2/package/${packageId}/set-status`
    return this.post(payload).then(response => {
      return response
    })
  }
  updateTrackingStatus(id, payload = {}) {
    this.endPoint = `api/v2/statusables/${id}`
    return this.put(payload).then(response => {
      return response
    })
  }
  async deleteTrackingStatus(id) {
    this.endPoint = 'api/v2/statusables'
    return this.delete(id).then(response => {
      return response
    })
  }
}
export default new PackageService()